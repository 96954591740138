<template>
  <CForm ref="form" class="needs-validation">
    <CSelect
        label="Ülke: "
        placeholder="Seçiniz.."
        horizontal
        :options="countries"
        :value.sync="data.countryId"
        @change="getCities"
    />
    <CSelect
        label="Eyalet: "
        placeholder="Seçiniz.."
        horizontal
        :options="states"
        :value.sync="data.stateId"
        :disabled="!disableStates.includes(data.countryId)"
    />
    <CSelect
        v-if="data.countryId === 192"
        label="Şehir: "
        placeholder="Seçiniz.."
        horizontal
        :options="cities"
        :value.sync="data.city"
        @change="getDistricts"
    />
    <CSelect
        v-if="data.countryId === 192"
        label="Semt: "
        placeholder="Seçiniz.."
        horizontal
        :options="districts"
        :value.sync="data.district"
    />
    <CInput
        v-else
        label="Şehir: "
        horizontal
        type="text"
        v-model="data.city"
    />
    <CTextarea
        label="Adres Satırı: "
        horizontal
        type="text"
        id="addressLine"
        v-model="data.addressLine"
    >
    </CTextarea>
    <CInput
        label="Posta Kodu: "
        horizontal
        type="text"
        id="postalCode"
        v-model="data.postalCode"
    >
    </CInput>
    <CInput
        label="Kişi Adı: "
        horizontal
        type="text"
        v-model="data.sAddressPersonName"
    >
    </CInput>
    <CInput
        label="Şirket Adı: "
        horizontal
        type="text"
        v-model="data.companyName"
    >
    </CInput>
    <CInput
        label="Vergi No: "
        horizontal
        type="text"
        v-model="data.companyTaxInfo"
    >
    </CInput>
  </CForm>
</template>

<script>
export default{
  name: "CargoAddressForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  computed: {
    countries: function(){
      let data = []
      this.$store.getters.allCountries.map(r => data.push({value:r.id, label: r.name}))
      return data
    },
    states: function(){
      let data = []
      this.$store.getters.allStates && this.$store.getters.allStates.map(r => data.push({value:r.id, label: r.name}))
      return data
    },
    cities: function(){
      let data = []
      this.$store.getters.allCities && this.$store.getters.allCities.map(r => data.push({value:r.name, label: r.name}))
      return data
    },
    districts: function(){
      let data = []
      this.$store.getters.allDistrict && this.$store.getters.allDistrict.map(r => data.push({value:r.name, label: r.name}))
      return data
    }
    
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},
      disableStates: [200, 37, 12]
    }
  },
  methods: {
    getCities: async function(){
        let params = {countryId: this.data.countryId}
        if(this.disableStates.includes(this.data.countryId)){
          await this.$store.dispatch('allStates_list', params)
        }else{
          await this.$store.dispatch('allCities_list', params)
        }
    },
    getDistricts: async function(){
        let params = {cityName: this.data.city}
        await this.$store.dispatch('allDistrict_list', params)
    },
    
  }
}
</script>